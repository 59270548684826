$(document).ready(function() {
    "use strict";

    //LEFT MOBILE MENU OPEN
    $(".show-menu").on('click', function() {
        $(".mm-menu").css('right', '-25%');
    });

    //LEFT MOBILE MENU OPEN
    $(".hide-menu").on('click', function() {
        $(".mm-menu").css('right', '-100%');
    });
    //GOOGLE MAP - SCROLL REMOVE
    $('.hp-view')
        .on('click', function() {
            $(this).find('iframe').addClass('clicked')
        })
        .on('mouseleave', function() {
            $(this).find('iframe').removeClass('clicked')
        });

    $('input#input_text, textarea#textarea1').characterCounter();
    Materialize.updateTextFields();
    $('.collapsible').collapsible();
    $('.materialboxed').materialbox();
    $('.carousel').carousel();
    $('select').material_select();
    $('.slider').slider();
    $('.dropdown-button').dropdown({
        inDuration: 300,
        outDuration: 225,
        constrainWidth: true, // Does not change width of dropdown to that of the activator
        hover: true, // Activate on hover
        gutter: 0, // Spacing from edge
        belowOrigin: false, // Displays dropdown below the button
        alignment: 'left', // Displays dropdown with edge aligned to the left of button
        stopPropagation: false // Stops event propagation
    });
});

//PORTFOLIO FILTER
$(function() {

    var filterList = {

        init: function() {

            // MixItUp plugin
            // http://mixitup.io
            $('#portfoliolist').mixItUp({
                selectors: {
                    target: '.portfolio',
                    filter: '.filter'
                },
                load: {
                    filter: '.logo'
                }
            });

        }

    };

    // Run the show!
    filterList.init();


});



//DATE PICKER   
$(function() {
    var dateFormat = "yy/mm/dd",
        from = $("#from")
        .datepicker({
            defaultDate: "+1w",
            changeMonth: false,
            numberOfMonths: 1
        })
        .on("change", function() {
            to.datepicker("option", "minDate", getDate(this));
        }),
        to = $("#to").datepicker({
            defaultDate: "+1w",
            changeMonth: false,
            numberOfMonths: 1
        })
        .on("change", function() {
            from.datepicker("option", "maxDate", getDate(this));
        }),
        departure_date = $("#departure_date").datepicker({
            defaultDate: "+1m",
            changeMonth: false,
            numberOfMonths: 1
        });

    function getDate(element) {
        var date;
        try {
            date = $.datepicker.parseDate(dateFormat, element.value);
        } catch (error) {
            date = null;
        }

        return date;
    }
});

$(function() {
    if($('.collapsible li.step-1').length > 0) {
        moveNext(1);
    }
});

$('.no-collapsable').on('click', function (e) {
    e.stopPropagation();
});

$('#frmLogin').on('submit', function(e) {

    e.preventDefault();

    frm = $(this);

    var url = frm.attr('action');
    var data = frm.serialize();

    $('#login-modal .alert').remove();

    $.post(url, data, function(res) {
        
        if(res['success']) {
            frm.before('<div class="alert alert-success">Thanks, you have been logged in, you are now being taken there ...</div>');
            window.location.reload();
            return false;
        }

        frm.before('<div class="alert alert-danger">' + res + '</div>');

    }, 'json');

    return false;
});

$('#frmAddBooking').on('submit', function(e){
    e.preventDefault();

    // console.log($(this).parsley().isValid());

    if(!$(this).parsley().isValid()) {
        return false;
    }

    frm = $(this);

    var url = frm.attr('action');
    var data = frm.serialize();

    $('.inn-booking .alert').remove();
    $.post(url, data, function(res) {
        
        if(res['success']) {
            frm.before('<div class="alert alert-success">Thanks, Booking Ref - ' + res['result']['bookingnumber'] +' - has been added</div>');
            // window.location.reload();
            return false;
        } else {
            frm.before('<div class="alert alert-danger">Booking add failed: ' + res['result'] + '</div>');
        }

        

    }, 'json').fail(function(e){
        frm.before('<div class="alert alert-danger">Error was encountered: ' + e + '</div>');
    });

});

$('#frmBookingGuestSearch').on('submit', function(e) {
    e.preventDefault();

    /*$(this).attr('action');*/
    frm = $('#frmBookingGuestSearch');
    var booking_ref = $.trim( $('#booking_ref').val() );

    if(!booking_ref) { $(this).before('<div class="alert alert-danger">Required Booking Ref.</div>'); return false; }

    $('#search-booking .alert').remove();

    var url = '/booking/search/' + booking_ref;

    $.get(url, function(resp) {
        if(resp) {

            if(resp['status'] == 'start') {
                frm.before('<div class="alert alert-success">We found your booking, now proceeding to Edit page.</div>');
                window.location = '/booking/edit/' + booking_ref;
                return true;
            } else {
                frm.before('<div class="alert alert-danger">Booking status  ' + resp['status'] + ': with Consultant, cannot be edited.</div>');
            }

        }
        frm.before('<div class="alert alert-danger">Requested Booking ref could not be found in our system, please check and try again!</div>');

    });
});

var global_booking_ref = false;
$('#frmBookingStep1').on('submit', function(e) {

    e.preventDefault();
    
    var frm = $(this);
    global_booking_ref = $('#booking_id').val();

    $('.step-1 .alert').remove();

    $.post('/booking/edit/' +  global_booking_ref+ '/step1', frm.serialize(), function(resp) {
        if(resp['success']) {
            /* close this -- open */
            frm.before('<div class="alert alert-success">Successfully saved, <a href="javascript:moveNext(2)">please proceed to Step 2</a></div>');
            moveNext(2);
        } else {
            frm.before('<div class="alert alert-danger">Errors encountered while saving the Booking:<br />' + resp['result'] + '</div>');
        }
    }, 'json');

    return false;
});

$('.add-pax').on('click', function() {
    if(!total_pax) { total_pax = 4; }

    var div = '.extra-' + total_pax;
    $(div).removeClass('hidden');
console.log(total_pax);
console.log(div);
    total_pax = total_pax + 1;
    return false;
})

$('#frmBookingStep2').on('submit', function(e){
    e.preventDefault();

    var frm = $(this);
    global_booking_ref = $('#booking_id').val();

    $('.step-2 .alert').remove();
    $.post('/booking/edit/' +  global_booking_ref+ '/step2', frm.serialize(), function(resp) {
        if(resp['success']) {
            /* close this -- open */
            frm.before('<div class="alert alert-success">Successfully saved, please proceed to Step 3</div>');
            moveNext(3);
        } else {
            frm.before('<div class="alert alert-danger">Errors encountered while saving Lead Passenger:<br />' + resp['result'] + '</div>');
        }
    }, 'json');

    return false;
});


$('#frmBookingStep3').on('submit', function(e){
    e.preventDefault();

    var frm = $(this);
    global_booking_ref = $('#booking_id').val();

    $('.step-3 .alert').remove();
    $.post('/booking/edit/' +  global_booking_ref+ '/step3', frm.serialize(), function(resp) {
        if(resp['success']) {
            /* close this -- open */
            frm.before('<div class="alert alert-success">Successfully saved, proceed to <a href="javascript:moveNext(4)">step 4</a> or add new</div>');
            resetForm3();
        } else {
            frm.before('<div class="alert alert-danger">Errors encountered while saving Passenger info:<br />' + resp['result'] + '</div>');
        }
    }, 'json');

    return false;
});

$('#frmBookingStep4').on('submit', function(e){
    e.preventDefault();

    var frm = $(this);
    global_booking_ref = $('#booking_id').val();

    $('.step-4 .alert').remove();

    if(!$('#accept_terms').prop('checked')) {
        console.log('hello here');
        frm.before('<div class="alert alert-danger">Please accept the terms and conditions.</div>');
        return false;
    }

    $.post('/booking/edit/' +  global_booking_ref+ '/step4', frm.serialize(), function(resp) {
        if(resp['success']) {
            /* close this -- open */
            frm.before('<div class="alert alert-success">Thanks, booking successfully saved.</div>');
            $('.typo-com').before('<div class="alert alert-success">Thanks, booking successfully saved.<br /><br />Your consultant will be in touch with you soon.</div>');
            $('.typo-com').remove()
            // moveNext(3);
        } else {
            frm.before('<div class="alert alert-danger">Errors encountered while saving Booking info:<br />' + resp['result'] + '</div>');
        }
    }, 'json');

    return false;
});


var resetForm3 = function() {
    $('.step-3 input:text').val('');
}

$('.continue').on('click', function(e) {
    moveNext(4);
})

var moveNext = function(number) {
    $('.collapsible li .collapsible-body').css('display', 'none');
    $('.collapsible li').removeClass('active');

    $('.collapsible li.step-' + number + ' .collapsible-body').css('display', 'block');
    $('.collapsible li.step-' + number).addClass('active');
}

var clearPassengerForm = function() {
    $('#p_firstname').val('');
    $('#p_lastname').val('');
    $('#p_idnumber').val('');
    $('#childage').val(18);
    $('#p_nationality').val('');
    $('#p_passport').val('');
    $('#p_passport_country').val('');

    return true;
}

if($('#bookingform').length) {
  $('#bookingform').parsley().on('field:validated', function() {
    var ok = $('.parsley-error').length === 0;
    $('.parsley-errors').toggleClass('hidden', ok);
    $('.parsley-success-div').toggleClass('hidden', !ok);
  });
}

if($('#frmAddBooking').length) {

    $('#frmAddBooking').parsley();
}
  // .on('form:submit', function() {
  //   return false; // Don't submit form for this demo
  // });

  $('#bookingform .save').on('click', function() {
    $('#frmSave').val('save');
    $('#bookingform').submit();
  });